// ----------------------------------------------------------------------

export default function useApiUrl() {
  let baseUrl = '';
  if (process.env.NODE_ENV !== 'production') {
    baseUrl = 'http://localhost:6543/';
  } else {
    baseUrl = 'https://apimanouna.benim.be/';
  }

  console.log(`baseurl: ${baseUrl}`);

  const manounaApiUrl = `${baseUrl}api/manouna/`;
  const appApiUrl = `${baseUrl}api/app/`;

  return { manounaApiUrl, appApiUrl };
}
